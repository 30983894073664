@import '../../../styles.scss';
.header-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
    left: 0;
}
.header{
    background-color: $text;
    min-height: 80px;
    display: flex;
    align-items: center;
    .container {
        max-width: 100%;
        padding: 0 15px;
    }
    .header-flex{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .down-arrow{
            filter: contrast(0) brightness(5);
        }
        .header-left{            
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .header-logo{
                width: 110px;
                height: auto;
                margin: 0 0px;
            }
            .p-button{
                margin: 0px 30px;
                display: flex;
                align-items: center;
                .down-arrow{
                    margin-left: 10px;
                    margin-top: 5px;
                    position: relative;
                }
            }
        }
        .header-center{
            display: flex;
            justify-content: center;
            .p-inputtext{
                width: 388px;
            }
        }
        .header-right{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .p-overlay-badge{
                display: flex;
                align-items: center;
                cursor: pointer;
            }
            .header__user{
                display: flex;
                align-items: center;
                margin-left: 15px;
                cursor: pointer;
                span{
                    display: flex;
                    align-items: center;
                    .pi-angle-down{
                        color: $white;
                    }
                }
            }
        }
    }
}
.top-toolbar-right-item{
    position: relative;
    .language-drop{
        display: flex;
        align-items: center;
        margin: 0 10px;
        cursor: pointer;
        .language-text{
            font-family: "OpenSans-Regular";
            font-size: 12px;
            color: #fff;
            margin: 0px 4px;
        }
    }
    .currency-drop{
        display: flex;
        align-items: center;
        margin: 0 10px;
        position: relative;
        cursor: pointer;
        .p-badge {
            position: absolute;
            right: -5px;
            top: -5px;
        }
        .currency-text{
            font-family: "OpenSans-Regular";
            font-size: 12px;
            color: #fff;
            margin: 0px 4px;
        }
    }  
    &~.top-toolbar-right-item{
        border-left: 1px solid $text;
        margin-left: 8px;
    } 
}

.notification-list {
    width: 270px;
    position: absolute;
    top: 56px;
    right: 0;
    background: #fff;
    border-radius: 8px;
    overflow: auto;
    box-shadow: 0 0 20px -9px #000;
    max-height: 350px;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: #CCCCCC transparent;
    scrollbar-width: thin;
    .p-menuitem-text {
        font-size: 14px;
        line-height: 20px;
    }
    .notification-list-item {
        padding: 10px;
        cursor: pointer;
        background: #f9ae0053;
        border-bottom: 1px solid #484848;
        &:hover {
            background: #f9ae008e;
        }
        &.read{
            background: transparent;
            filter: brightness(0.5);
            &:hover{
                background: transparent;
            }
        }
    }
    
    .notification-list-more {
        padding: 6px 10px;
        text-align: center;
        border-top: 1px solid #f5f5f5;
        cursor: pointer;
    }
}