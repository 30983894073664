.full-page-loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.8); /* Fallback color */
  }
  
  .spinner-container {
    position: relative;
    width: 80px; /* Adjust width as needed */
    height: 80px; /* Adjust height as needed */
    background-image: url('/public/favicon.png'); /* Replace 'your-image-path.jpg' with the actual path to your image */
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
  
  .spinner-text {
    color: black;
    margin-bottom: 10px;
  }
  
  .spinner {
    color: white;
  }
  