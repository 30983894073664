// text heading paragraph

.h1{
    @include h1-heading($text);
}
.h2{
    @include h2-heading($text);
}
.h3{
    @include h3-heading($text);
}
.h4{
    @include h4-heading($text);
}
.h5{
    @include h5-heading($text);
}
.h6{
    @include h6-heading($text);
}
.p1{
    @include p1-paragraph($text);
}
.p2{
    @include p2-paragraph($text);
}
.p3{
    @include p3-paragraph($text);
}
.p4{
    @include p4-paragraph($text);
}
// link
.link{
    @include p2-paragraph($yellow, $family: 'PublicSans-SemiBold');
    text-decoration: none;
    display: flex;
    align-items: center;
    .link-content{
        margin: 0px 8px;
    }
    .pi{
        color: $yellow;
        transition: all 0.3s ease-in-out;
    }  
    &:hover{
        .pi{
            transform: translateX(5px);
        }
    }
}

.btn{
    justify-content: center;
    font-family: 'PublicSans-Medium';
    font-weight: 500;
    font-size: 16px;
    &.btn-success{
        background:#64CD82;
        border: 1px solid #64CD82;
        &:hover{
            background:#64CD82;
            border: 1px solid #64CD82;
        }
    }
    &.btn-danger{
        background:#db2727;
        border: 1px solid #db2727;
        &:hover{
            background:#db2727;
            border: 1px solid #db2727;
        }
    }
    &.small{
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        padding:0 15px;
    }
    &.orange-outline{
        background:transparent;
        border: 1px solid #FBB03B;
        color: #FBB03B;
        &:hover{
            background:transparent;
            border: 1px solid #FBB03B;
            color: #FBB03B;
        }
    }
}
.container{
    width: 100%;
    max-width: 1266px;
    margin: auto;
}
.icon24{
    width: 24px;
    height: 24px;
    object-fit: contain;
}
// toolbar sticky
.toolbar-sticky{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: $outline;
    height: 44px;
    .toolbar-content{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .toolbar-link{
            display: flex;
            align-items: center;
            text-decoration: none;
            margin: 0px 18px;
            &:first-child{
                margin-left: 0px;
            }
            &:last-child{
                margin-right: 0px;
            }
            .icon24{
                filter: contrast(0) brightness(0.5);
            }
            .tool-content{
                @include p2-paragraph($text);
                margin: 0px 8px;
            }
            &:focus,&:active,&.active{
                .icon24{
                    filter: contrast(1) brightness(1);
                }
                .tool-content{
                    color: $yellow;
                }
            }   
        }
    }
}

// chips
.chips{
    padding: 4px 14px;
    border-radius: 6px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 12px !important;
    font-family: 'PublicSans-Medium' !important;
    font-weight:500;
    &.error{
        background-color: $error;
    }
    &.success{
        background: #EFF8EB;
        color: #59AA00;
    }
    &.danger{
        background: #FCF3F2;
        color: #D23D50;
    }
    &.confirmed{
        background: #F0F9FD;
        color: #27AAE2;
    }
    &.warning{
        background: #FFF6ED;
        color: #EE9C16;
    }
    &.delivered{
        background: #EFF8EB;
        color: #59AA00;
    }
    &.canceled{
        background: #FCF3F2;
        color: #D23D50;
    }
    &.returned{
        background: #fddeda;
        color: #ad2a3a;
    }
}

// product box
.product-box{
    transition: all 300ms;
    .product-image-box{
        width: 100%;
        height: 243px;
        background-color: $white;
        border-radius: 8px;
        overflow: hidden;
        display: flex;  
        align-items: center;
        justify-content: center;
        position: relative;
        .product-image{
            width: 100%;
            height: 100%;
            object-fit: scale-down;
        }
        .add-to-card-btn{
            height: 54px;
            width: calc(100% - 40px);
            position: absolute;
            bottom: -55px;
            visibility: hidden;
            opacity: 0;
            background-color: $cta2;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 300ms;
            @media (max-width: 899px) {
                bottom: 12px;
                visibility: visible;
                opacity: 1;
            }
        }
    }
    .product-content{
        padding: 20px 0px;
        .p3{
            @include p3-paragraph($cta2, $family: 'PublicSans-SemiBold');
            margin-bottom: 8px;
        }
        .h6{
            @include h6-heading($text, $family: 'PublicSans-Bold');   
            margin-bottom: 8px;
            font-size: 14px;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .price-row{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .p2{
                @include p2-paragraph($text, $family: 'PublicSans-Bold');
                margin-bottom: 0px;
            }
            .p3{
                @include p3-paragraph($error, $family: 'PublicSans-Regular');
                text-decoration: line-through;
                margin: 0px 8px;
            }
        }
    }
    &:hover{
        .product-image-box{
            .add-to-card-btn{
                visibility: visible;
                opacity: 1;
                bottom: 12px;
            }
        }
    }
}


.tablefilterSubheader{
    border-bottom: 1px solid #d9dee3 !important;
    padding: 24px 24px;

}

.tablefilterheader{
    .tableFilterCol{
        margin-left: 20px;
        width: 250px;
        .p-dropdown.p-component.p-inputwrapper {
            width: 100% !important;
        }
    }
}
.fieldLeftCaption .currencycapstiondd {
    
    background: #f2f2f2;
}

.sidebarContentBody{
    .p-invalid {
        bottom: -11px !important;
    }
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #faaf3b !important;
}
.p-inputswitch .p-inputswitch-slider {
    outline: 0 !important;
    box-shadow: none !important;
   
}

.show-dataonly-static{
    width: 100%;
    min-height: 38px;
    border: 1px solid #80808096;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 14px;
}

.dilogbtns {
    display: flex;
     .btn {
        width: 100%;
        display: block;
        text-align: center;
    }
}
.p-dialog-header {
    border-bottom: 1px solid #ccc !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: #fbb03b;
    color: #fbb03b;
}
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    background-color: #fbb03b;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
}
.uploadProfileImage {
    position: absolute;
    background: #fff;
    width: 35px !important;
    height: 35px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    bottom: -20px;
    box-shadow: 0 4px 2px 0 rgb(0 0 0 / 21%);
    cursor: pointer;
}
.itemListul{
    padding-left: 0;
    li{
        position: relative;
        list-style: none;
        padding-left: 34px;
        font-size: 14px;
        margin-bottom: 10px;
        font-family: "PublicSans-Regular";
        font-size: 14px;
        &.w-auto{
            width: auto;
        }
        i{
            color: #27AAE2;
            left: 0;
            top: 5px;
            position: absolute;
        }
        i.pi-times{
            position: relative;
            color: var(--text);
            top: 0px;
            background: #d5d5d5;
            padding: 4px;
            font-size: 10px;
            border-radius: 50%;
        }
    }
}
.blankHeader .p-dialog-header{
    border-bottom: 0 !important;
    padding: 15px 20px 0 20px !important
}

.p-column-title {
    white-space: nowrap;
}
.min-w-240{
    min-width: 240px;
}