:root {
    --white: #FFFFFF;
    --cta1: #1A3945;
    --cta2: #64CD82;
    --secondary: hwb(172 12% 34%);
    --yellow: #F9B000;
    --text: #12262F;
    --gray: #959595;
    --outline: #EDF4F4;
    --inside: #FAFCFC;
    --error: #E93D3D;
    --confirmed:#27AAE2;
    --delivered:#59AA00;
    --grayf5:#f5f5f9;
    --grayd2:#d2d7d7;
    --grayd9:#d9dee3;
    --black566:#566a7f;
    --grayfb:#fbfbfb;
}

$white: #FFFFFF;
$cta1: #1A3945;
$cta2: #64CD82;
$secondary: #1EA896;
$yellow: #F9B000;
$text: #12262F;
$gray: #959595;
$outline: #EDF4F4;
$inside: #FAFCFC;
$error: #E93D3D;
$confirmed: #27AAE2;
$delivered: #59AA00;
$grayf5: #f5f5f9;
$grayd2: #d2d7d7;
$grayd9: #d9dee3;
$black566: #566a7f;
$grayfb: #fbfbfb;

